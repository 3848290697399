<template>
  <div id="UserList">
    <title-nav :title="'회원수 현황'" :nav="'회원관리 / 회원수현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col>
              <b-input-group size="sm">
                <b-input-group-append>
                  <datepicker
                    id="from-datepicker-util"
                    v-model="start_date"
                    v-on:selected="changeStartDate"
                    name="start_date"
                    :value="start_date.date"
                    format="yyyy/MM/dd"
                    placeholder="시작일"
                    input-class="form-control custom-select-sm"
                  />
                  ~
                  <datepicker
                    id="to-datepicker-util"
                    v-model="end_date"
                    v-on:selected="changeEndDate"
                    name="end_date"
                    :value="end_date.date"
                    format="yyyy/MM/dd"
                    placeholder="종료일"
                    input-class="form-control custom-select-sm"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center table-head-fixed"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { getBatchUserCountList } from '@/api/user'

var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), now.getMonth(), 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'UserCount',
  components: {
    Datepicker
  },
  data() {
    return {
      page: 1,
      records: 0,
      rowPerPage: 20,
      isBusy: false,
      start_date: this.$moment(monthFirstDate).format('YYYY/MM/DD'),
      end_date: this.$moment(monthLastDate).format('YYYY/MM/DD'),
      fields: [
        {
          key: 'yyyymmdd',
          label: '날짜',
          colType: 'span',
          thStyle: { width: '25%' }
        },
        {
          key: 'temp',
          label: '임시',
          colType: 'span',
          thStyle: { width: '25%' }
        },
        {
          key: 'normal',
          label: '정상',
          colType: 'span',
          thStyle: { width: '25%' }
        },
        {
          key: 'withdraw',
          label: '탈퇴',
          colType: 'span',
          thStyle: { width: '25%' }
        }
      ],
      items: [],
      striped: true,
      bordered: false,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark'
    }
  },
  methods: {
    async init() {
      this.isBusy = true

      this.getList()

      this.isBusy = false
    },
    async getList() {
      this.isBusy = true

      const startDate = this.$moment(this.start_date).format('YYYYMMDD')
      const endDate = this.$moment(this.end_date).format('YYYYMMDD')
      const response = await getBatchUserCountList(this.page, this.rowPerPage, startDate, endDate)
      if (response.code === '0000') {
        this.items = response.data.list
        this.records = response.data.totCnt
      } else {
        alert(response.message)
      }
      this.isBusy = false
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY/MM/DD')
      this.page = 1
      this.reset()
      this.init()
    },
    changeEndDate(val) {
      this.end_date = this.$moment(val).format('YYYY/MM/DD')
      this.page = 1
      this.reset()
      this.init()
    },
    reset() {
      this.items = []
      this.records = 0
    }
  },
  mounted() {
    this.init()
  }
}
</script>
